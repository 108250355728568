import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ISubmitFeedback {
  liked: boolean;
  rating?: string[];
  comment?: string;
  question_id: string;
  prompt: string;
  answer: string;
  user_id: string;
  conversation_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  private url = `${environment.BACKEND_URL}/api/feedbacks/`;

  submitFeedback({
    liked,
    rating,
    comment,
    question_id,
    user_id,
    conversation_token,
    prompt,
    answer,
  }: ISubmitFeedback) {
    if (liked) {
      return this.http.post(this.url, {
        liked,
        user_id,
        conversation_token,
        question_id,
        prompt,
        answer,
      });
    } else {
      return this.http.post(this.url, {
        liked,
        rating,
        comment,
        user_id,
        conversation_token,
        question_id,
        prompt,
        answer,
      });
    }
  }
}
