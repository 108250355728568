import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, throwError } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { CONSTANTS } from '../constants';
import { AuthActions } from './auth.actions';
import { selectUserName } from './auth.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store
  ) {}

  getUserPhoto$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserDataSuccess),
      concatLatestFrom(() => this.store.select(selectUserName)),
      exhaustMap(([, userName]) =>
        from(
          this.http
            .get(CONSTANTS.USER_PHOTO_URL, { responseType: 'blob' })
            .pipe(
              map((photo) => URL.createObjectURL(photo)),
              catchError(() => {
                return throwError(() => 'Error getting user photo');
              })
            )
        ).pipe(
          map((photo) => {
            return AuthActions.getUserPhotoSuccess({ photo });
          }),
          catchError(() => {
            const photo = `https://ui-avatars.com/api/?name=${userName}`;

            return of(AuthActions.getUserPhotoFailure({ photo }));
          })
        )
      )
    );
  });
}
