import { ErrorHandler, NgModule, isDevMode } from '@angular/core';

import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { LoggingService } from './core/services/logging.service';
import { APIMInterceptor } from './interceptors/apim.interceptor';
import { chatKey } from './chat/state/chat.selectors';
import { chatReducer } from './chat/state/chat.reducer';
import { authKey } from './auth/state/auth.selectors';
import { authReducer } from './auth/state/auth.reducer';
import { ChatEffects } from './chat/state/chat.effects';
import { AuthEffects } from './auth/state/auth.effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CookieBannerModule } from './components/cookie-banner/cookie-banner.module';
import { TermsOfUseModule } from './components/terms-of-use/terms-of-use.module';
import { sidebarKey } from './components/header/sidebar/state/sidebar.selectors';
import { sidebarReducer } from './components/header/sidebar/state/sidebar.reducer';
import { SidebarEffects } from './components/header/sidebar/state/sidebar.effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HighlightModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    CookieBannerModule,
    TermsOfUseModule,
    StoreModule.forRoot({
      [chatKey]: chatReducer,
      [authKey]: authReducer,
      [sidebarKey]: sidebarReducer,
    }),
    EffectsModule.forRoot(ChatEffects, AuthEffects, SidebarEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    AuthModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: APIMInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: LoggingService,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppModule {}
