<div id="cookie-banner" class="cookie-modal">
  <div
    class="cookie-modal__content"
    [ngClass]="{
      hidden: hasShownCookieBanner
    }"
  >
    <div class="cookie-modal__title">
      <h2>Cookie Settings</h2>
      <button (click)="dontShowBannerAnymore()">X</button>
    </div>
    <div class="cookie-modal__text-container">
      <p class="cookie-modal__text">
        ANHEUSER-BUSCH INBEV SA/NV, and its affiliates (collectively “ABI”,
        “we”, “our”) use first and third party cookies and similar technologies
        to process personal data and end device information when you use
        GPTap(the “Tool”). For a list of third parties providing cookies in this
        context, please click here . We use cookies to enable and improve the
        Tool and to monitor its use. To understand more about how we use cookies
        or to change cookie preferences, please read our Cookie Notice, Privacy
        Notice and click on “Cookie Settings”. By clicking “Accept All Cookies"
        you agree to the storing of all cookies on your device consistent with
        our Cookie Notice, Privacy Notice and the information within the linked
        Privacy Preference Center. If you click “Decline”, only strictly
        necessary cookies will be placed. You can also choose to customize your
        cookie preferences or withdraw your consent to the use of optional
        cookies at any time, by clicking "Cookies Settings”. Depending on your
        selected cookie preferences, the full functionality of the Tool may not
        be available.
      </p>
    </div>
    <div class="cookie-modal__options">
      <div class="cookie-modal__option">
        <input
          type="checkbox"
          id="essential-cookies"
          class="cookie-modal__checkbox"
          checked
          disabled
        />
        <label for="essential-cookies" class="cookie-modal__label"
          >Essential Cookies</label
        >
      </div>
    </div>
    <div class="cookie-modal__button-container">
      <button
        (click)="onAcceptClick()"
        id="accept-cookie"
        class="cookie-modal__button"
      >
        Accept
      </button>
      <button
        (click)="onDeclineClick()"
        id="decline-cookie"
        class="cookie-modal__button"
      >
        Decline
      </button>
    </div>
  </div>
</div>
