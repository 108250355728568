import { Component } from '@angular/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent {
  localStorageCookiesKey = 'v1.0.0@stellar.hasShownCookieBanner';
  hasShownCookieBanner =
    localStorage.getItem(this.localStorageCookiesKey) === 'true';

  onAcceptClick() {
    this.dontShowBannerAnymore();
  }

  onDeclineClick() {
    this.dontShowBannerAnymore();
  }

  dontShowBannerAnymore() {
    localStorage.setItem(this.localStorageCookiesKey, 'true');
    this.hasShownCookieBanner = true;
  }
}
