import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { selectAccessToken } from 'src/app/auth/state/auth.selectors';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { isToday } from 'date-fns';
import { ChatActions } from 'src/app/chat/state/chat.actions';

interface IConversation {
  answer: string;
  completionTokens: number;
  conversationContexts: string[];
  conversationToken: string;
  createAt: string;
  eventType: string;
  prompt: string;
  promptTokens: number;
  template: string;
  totalCost: number;
  totalTokens: number;
  userId: string;
  question_id: string;
}

interface ITypingConversation {
  chunk: string;
  conversationToken: string;
  createAt: string;
  eventType: string;
  userId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private accessToken$ = this.store.select(selectAccessToken);
  private lastDayTokenLimitToastWasShown =
    localStorage.getItem('lastDayTokenLimitToastWasShown') || '';

  socket!: Socket;

  start() {
    this.accessToken$.subscribe((accessToken) => {
      this.socket = io(environment.SOCKET_BACKEND_URL, {
        forceNew: true,
        transports: ['websocket'],
        query: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.onError();

      this.socket.on('onDailyTokenLimitReachedEvent', () => {
        const tokenLimitWasNotShownToday = !isToday(
          new Date(this.lastDayTokenLimitToastWasShown)
        );

        if (tokenLimitWasNotShownToday) {
          this.showDailyToastr();
        }
      });
    });

    return this;
  }

  onError() {
    return this.socket.on('onError', () => {
      this.showErrorToastr();
      this.store.dispatch(ChatActions.conversationWithError());
    });
  }

  onConversationCompleted(): Observable<IConversation> {
    return new Observable((observer) => {
      this.socket.on('onConversationCompleted', (msg) => {
        observer.next(msg);
      });
    });
  }

  onConversationTyping(): Observable<ITypingConversation> {
    return new Observable((observer) => {
      this.socket.on('onConversationTyping', (msg) => {
        observer.next(msg);
      });
    });
  }

  onConnected(): Observable<string> {
    return new Observable((observer) => {
      this.socket.on('onConnect', () => observer.next());
    });
  }

  createRoom(conversationToken?: string) {
    this.socket.emit('createRoom', { conversationToken });
    return this;
  }

  onCreatedRoom(): Observable<string> {
    return new Observable((observer) => {
      this.socket.on('onCreateRoom', (roomId: string) => {
        observer.next(roomId);
      });
    });
  }

  sendMessage(message: {
    conversationToken: string;
    prompt: string;
    userId: string;
    hasFile: boolean;
  }) {
    return this.socket.emit('conversationStarted', {
      conversationToken: message.conversationToken,
      prompt: message.prompt,
      userId: message.userId,
      context_name: message.hasFile ? message.conversationToken : null,
    });
  }

  private showDailyToastr() {
    localStorage.setItem(
      'lastDayTokenLimitToastWasShown',
      new Date().toString()
    );

    this.translate
      .get('CORE.SOCKET.TOASTR')
      .subscribe((toastr: { TITLE: string; DESCRIPTION: string }) => {
        this.toastr.error(toastr.TITLE, toastr.DESCRIPTION, {
          progressBar: true,
          closeButton: true,
        });
      });
  }

  private showErrorToastr() {
    this.translate
      .get('CORE.SOCKET.TOASTR')
      .subscribe(
        (toastr: { ERROR_TITLE: string; ERROR_DESCRIPTION: string }) => {
          this.toastr.error(toastr.ERROR_TITLE, toastr.ERROR_DESCRIPTION, {
            progressBar: true,
            closeButton: true,
          });
        }
      );
  }
}
