import { Injectable, NgZone } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AutomaticLogoutService {
  constructor(
    private ngZone: NgZone,
    private authService: MsalService
  ) {
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }

  getLastAction(): string {
    return localStorage.getItem('lastAction') || '0';
  }

  lastAction(value: number) {
    localStorage.setItem('lastAction', JSON.stringify(value));
  }

  dispatchUserActiveEvent() {
    const syntheticEvent = new Event('userActive');

    document.body.dispatchEvent(syntheticEvent);
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('userActive', () => {
        this.reset();
      });
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    });
  }

  reset() {
    this.lastAction(Date.now());
  }

  check() {
    const eightHours = 8 * 60 * 60 * 1000;
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + eightHours;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('lastAction');

        this.authService.logoutRedirect();
      }
    });
  }
}
